///////////// 
import { initializeApp } from "firebase/app";

//import { getApp } from "@firebase/app";
// Import the functions you need from the SDKs you need
import { getAuth, onAuthStateChanged, GoogleAuthProvider, signOut } from "firebase/auth";
import { getFirestore, collection, doc, getDocs, query, where, onSnapshot } from "firebase/firestore";
//import firebaseui from 'firebaseui';

// Replace with your Firebase project config.
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCxIcM8B_eQIF9wvXHhs6DxQC3a34sHI4E",
  authDomain: "tarotfinder.com", //"tarotfinder.web.app",   //custom domain name is mandatory for security auth
  databaseURL: "", //empty since we don't use realtime db
  projectId: "tarotfinder",
  storageBucket: "tarotfinder.appspot.com", //"tarotfinder.firebaseapp.com", //use appspot.com since we might be using google app engine with storage
  messagingSenderId: "470141690005",
  appId: "1:470141690005:web:08ce92da03538fb5283b9c",
  measurementId: "G-JS1BTN6MV0"
};
// Replace with your cloud functions location
const functionLocation = 'us-central1';
const prices = {};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

//const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
//const auth = getAuth(app);
const db = getFirestore(app);

import { getStripePayments } from "@invertase/firestore-stripe-payments";
const payments = getStripePayments(app, {
  productsCollection: "products",
  customersCollection: "customers",
});




// Sign-in function
function signInWithGoogle() {
  signInWithPopup(auth, provider)
    .then((result) => {
      console.log('User signed in:', result.user);
    })
    .catch((error) => {
      console.error('Error during sign-in:', error);
    });
}

// Listen for authentication state changes
onAuthStateChanged(auth, (user) => {
  if (user) {
    console.log('User is signed in:', user);
  } else {
    console.log('No user is signed in.');
  }
});
///////////////////////////






// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries




//  firebasestorage.app
//  The name format for new default Cloud Storage buckets, starting October 30, 2024. These buckets are not associated with Google App Engine.
//  appspot.com
//  The name format for default Cloud Storage buckets created before October 30, 2024. These buckets are associated with Google App Engine.

// find firebase config here: https://tarotfinder.com/__/firebase/init.json
//{
//  "apiKey": "AIzaSyCxIcM8B_eQIF9wvXHhs6DxQC3a34sHI4E",
//  "appId": "1:470141690005:web:08ce92da03538fb5283b9c",
//  "authDomain": "tarotfinder.firebaseapp.com",
//  "databaseURL": "",
//  "measurementId": "G-JS1BTN6MV0",
//  "messagingSenderId": "470141690005",
//  "projectId": "tarotfinder",
//  "storageBucket": "tarotfinder.firebasestorage.app"
//}



// Initialize Firebase
//const firebaseApp = app.initializeApp(firebaseConfig);
// Initialize Analytics and get a reference to the service
//const analytics = app.analytics();
//const db = firebaseApp.firestore();

//app.analytics().logEvent('notification_begin');

// Initialize Firebase
//const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
//analytics().logEvent('notification_begin');

/**
 * Firebase Authentication configuration
 */
/*fix this: https://firebase.google.com/docs/auth/web/redirect-best-practices?hl=en&authuser=2&_gl=1*1k4agwa*_ga*MzA5MzU3MjQwLjE3MzAxNjYyNTU.*_ga_CW55HF8NVT*MTczMDE2NjI1NC4xLjEuMTczMDE2NjM1Ny4yNi4wLjA.#web*/
// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
//https://console.firebase.google.com/u/2/project/tarotfinder/settings/general/web:ZWFmMWEzMmItMzUzNC00NzdiLTk4ZjYtOTgzN2E1NWY0MzZm
// Initialize Firebase

/**/
//const firebaseUI = new firebaseui.auth.AuthUI(app.auth());

/*
const firebaseUiConfig = {
  callbacks: {
    signInSuccessWithAuthResult: function (authResult, redirectUrl) {
      // User successfully signed in.
      // Return type determines whether we continue the redirect automatically
      // or whether we leave that to developer to handle.
      return true;
    },
    uiShown: () => {
      document.querySelector('#loader').style.display = 'none';
    },
  },
  signInFlow: 'popup',
  signInSuccessUrl: '/',
  signInOptions: [
    app.auth.GoogleAuthProvider.PROVIDER_ID,
    app.auth.EmailAuthProvider.PROVIDER_ID,
  ],
  credentialHelper: firebaseui.auth.CredentialHelper.NONE,
  // Your terms of service url.
  tosUrl: 'https://example.com/terms',
  // Your privacy policy url.
  privacyPolicyUrl: 'https://example.com/privacy',
};


app.auth().onAuthStateChanged((firebaseUser) => {
    const signInButton = document.querySelector('#signin-btn');
    const signOutButton = document.querySelector('#signout');
    const stripePortalButton = document.querySelector('#billing-portal-button');
    if (firebaseUser) {
        // User is signed in
        document.querySelector('#loader').style.display = 'none';
        //document.querySelector('main').style.display = 'block';
        currentUser = firebaseUser.uid;
        console.log(currentUser)
        console.log('<-- user logged in')
        startDataListeners();
        signInButton.style.display = 'none'; // Hide Sign In button
        signOutButton.style.display = 'block'; // Show Sign Out button
        stripePortalButton.style.display = 'block';
    } else {
        // No user is signed in
        //document.querySelector('main').style.display = 'none';
        firebaseUI.start('#firebaseui-auth-container', firebaseUiConfig);
        signInButton.style.display = 'block';
        signOutButton.style.display = 'none';
        stripePortalButton.style.display = 'none';
    }
});
*/

/**
 * Data listeners
 */
function startDataListeners() {
    // Check subscription status for the user
    db.collection('customers')
      .doc(currentUser)
      .collection('subscriptions')
      .where('status', 'in', ['trialing', 'active'])
      .onSnapshot(async (snapshot) => {
        if (snapshot.empty) {
          // User is not subscribed or has no active subscriptions
          // Update UI to reflect this status
          // For example, show a message or a subscribe button
          //updateSubscriptionStatusUI(false, 0);
          console.log(currentUser)
          console.log('user is not subscriibed')
        } else {
          // User has an active subscription
          const subscription = snapshot.docs[0].data();
          // You can also check for remaining reading quantity here
          // For example, if you store it in subscription data
          const remainingReadings = subscription.remainingReadings || 0;
          //updateSubscriptionStatusUI(true, remainingReadings);
          console.log(currentUser)
          console.log('user is subscriibing already')
        }
      });

    // Get all our products and render them to the page
    const products = document.querySelector('.products');
    const template = document.querySelector('#product');
    db.collection('products')
        .where('active', '==', true)
        .get()
        .then(function (querySnapshot) {
        querySnapshot.forEach(async function (doc) {
            const priceSnap = await doc.ref
            .collection('prices')
            .where('active', '==', true)
            .orderBy('unit_amount')
            .get();
            if (!'content' in document.createElement('template')) {
            console.error('Your browser doesn’t support HTML template elements.');
            return;
            }

            const product = doc.data();
            const container = template.content.cloneNode(true);

            container.querySelector('h2').innerText = product.name.toUpperCase();
            container.querySelector('.description').innerText =
            product.description?.toUpperCase() || '';
            // Prices dropdown
            priceSnap.docs.forEach((doc) => {
            const priceId = doc.id;
            const priceData = doc.data();
            prices[priceId] = priceData;
            const content = document.createTextNode(
                `${new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: priceData.currency,
                }).format((priceData.unit_amount / 100).toFixed(2))} per ${
                priceData.interval ?? 'once'
                }`
            );
            const option = document.createElement('option');
            option.value = priceId;
            option.appendChild(content);
            container.querySelector('#price').appendChild(option);
            });

            if (product.images.length) {
            const img = container.querySelector('img');
            img.src = product.images[0];
            img.alt = product.name;
            }

            const form = container.querySelector('form');
            form.addEventListener('submit', subscribe);

            products.appendChild(container);
        });
        });
    // Get all subscriptions for the customer
    db.collection('customers')
        .doc(currentUser)
        .collection('subscriptions')
        .where('status', 'in', ['trialing', 'active'])
        .onSnapshot(async (snapshot) => {
        if (snapshot.empty) {
            // Show products
            document.querySelector('#subscribe').style.display = 'block';
            return;
        }
        document.querySelector('#subscribe').style.display = 'none';
        document.querySelector('#my-subscription').style.display = 'block';
        // In this implementation we only expect one Subscription to exist
        const subscription = snapshot.docs[0].data();
        const priceData = (await subscription.price.get()).data();
        document.querySelector(
            '#my-subscription p'
        ).textContent = `You are paying ${new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: priceData.currency,
        }).format((priceData.unit_amount / 100).toFixed(2))} per ${
            priceData.interval
        }, giving you the role: ${await getCustomClaimRole()}. 🥳`;
        });
}

/**
 * Event listeners
 */
document.addEventListener('DOMContentLoaded', () => {
  const signinBtn = document.getElementById('signin-btn');
  if (signinBtn) {
    signinBtn.onclick = function() {
      document.getElementById('popup-container').style.display = 'block';
    };
  }
});

// Signout button
//document
//  .getElementById('signout')
//  .addEventListener('click', () => app.auth().signOut());

// Checkout handler
async function subscribe(event) {
  event.preventDefault();
  document.querySelectorAll('button').forEach((b) => (b.disabled = true));
  const formData = new FormData(event.target);
  const selectedPrice = {
    price: formData.get('price'),
  };
  // For prices with metered billing we need to omit the quantity parameter.
  // For all other prices we set quantity to 1.
  if (prices[selectedPrice.price]?.recurring?.usage_type !== 'metered')
    selectedPrice.quantity = 1;
  const checkoutSession = {
    automatic_tax: true,
    tax_id_collection: true,
    collect_shipping_address: true,
    allow_promotion_codes: true,
    line_items: [selectedPrice],
    success_url: window.location.origin,
    cancel_url: window.location.origin,
    metadata: {
      key: 'value',
    },
  };
  // For one time payments set mode to payment.
  if (prices[selectedPrice.price]?.type === 'one_time') {
    checkoutSession.mode = 'payment';
    checkoutSession.payment_method_types = ['card', 'sepa_debit', 'sofort'];
  }

  const docRef = await db
    .collection('customers')
    .doc(currentUser)
    .collection('checkout_sessions')
    .add(checkoutSession);
  // Wait for the CheckoutSession to get attached by the extension
  docRef.onSnapshot((snap) => {
    const { error, url } = snap.data();
    if (error) {
      // Show an error to your customer and then inspect your function logs.
      alert(`An error occured: ${error.message}`);
      document.querySelectorAll('button').forEach((b) => (b.disabled = false));
    }
    if (url) {
      window.location.assign(url);
    }
  });
}
// Ensure Webflow is defined
window.Webflow ||= [];
window.Webflow.push(() => {
  // Your DOM manipulation and event listeners go here

  console.log('Webflow is ready');

  document.addEventListener('DOMContentLoaded', () => {
    document.getElementById('signin-btn').onclick = function() {
      document.getElementById('popup-container').style.display = 'block';
    }
    
    document.getElementsByClassName('close-btn')[0].onclick = function() {
        document.getElementById('popup-container').style.display = 'none';
    }
  
    // Billing portal handler
    document.querySelector('#billing-portal-button').addEventListener('click', async (event) => {
        // Show the overlay and spinner
        document.getElementById('overlay').style.display = 'block';  
        document.querySelectorAll('button').forEach((b) => (b.disabled = true));
        // Call billing portal function
        const functionRef = firebase
          .app()
          .functions(functionLocation)
          .httpsCallable('ext-firestore-stripe-payments-createPortalLink');
        try {
            const { data } = await functionRef({ 
                returnUrl: window.location.origin,
                locale: "auto", // Optional, defaults to "auto" 
                //configuration: "bpc_1JSEAKHYgolSBA358VNoc2Hs", //"bpc_1MrnZsLkdIwHu7ixNiQL1xPM", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
            });
            window.location.assign(data.url);
        } catch (error) {
            console.error('Error fetching billing portal link:', error);
            // Hide the overlay and spinner in case of an error
            document.getElementById('overlay').style.display = 'none';
        }
    });
  });
  // Scroll to Next Section Event Listener
  const scrollArrow = document.querySelector('.scroll-down-arrow');
  if (scrollArrow) {
      scrollArrow.addEventListener('click', () => {
          const nextSection = document.getElementById('next-section');
          if (nextSection) {
              nextSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
          } else {
              console.error('next-section not found');
          }
      });
  } else {
      console.error('scroll-down-arrow not found');
  }

});
/*
document
  .querySelector('#billing-portal-button')
  .addEventListener('click', async (event) => {
    document.querySelectorAll('button').forEach((b) => (b.disabled = true));

    // Call billing portal function
    const functionRef = firebase
      .app()
      .functions(functionLocation)
      .httpsCallable('ext-firestore-stripe-payments-createPortalLink');
      //.httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
    const { data } = await functionRef({ returnUrl: window.location.origin });
    window.location.assign(data.url);
  });
  */

// Get custom claim role helper
async function getCustomClaimRole() {
  await app.auth().currentUser.getIdToken(true);
  const decodedToken = await app.auth().currentUser.getIdTokenResult();
  return decodedToken.claims.stripeRole;
}



// Close the popup if user clicks anywhere outside of it
window.onclick = function(event) {
    if (event.target == document.getElementById('popup-container')) {
        document.getElementById('popup-container').style.display = "none";
    }
}

function scrollToNextSection() {
    const nextSection = document.getElementById('next-section');
    nextSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
}


//lottie.loadAnimation({
//    container: document.getElementById('lottie-arrow'), // the DOM element that will contain the animation
//    renderer: 'svg',
//    loop: true,
//    autoplay: true,
//    path: 'scroll_down.json' // the path to the animation json
//});
